// import { createEffect, attach, createStore } from "effector"
import { AxiosResponse } from "axios"
import { AuthClient } from "ftl-core"

//types
import {
  createUtilsRepository,
  createRepositoryInstance,
  createProfileRepository,
  createEntityRepository,
  createCountryRepository,
  BasicResponse,
  ListResponse,
} from "ftl-core"
import {
  OrderSyncServiceAccount,
  User,
  UserRequest,
  Role,
  ExternalService,
  ExternalServiceRequest,
  ExternalServicePropertyRequest,
  ExternalServiceProperty,
  ExternalServiceAccountRequest,
  ExternalServiceAccount,
  SMS,
  SMSRequest,
} from "../types/entities"
import { Brand, BrandRequest } from "../types/entities/brand"
import {
  Organization,
  OrganizationRequest,
} from "../types/entities/organization"
import { RoleRequest } from "../types/entities/role"

enum Versions {
  v1 = "v1",
  v2 = "v2",
}

// export const baseUrl =
//   process.env.REACT_APP_ENV === "dev"
//     ? `https://ftl-tech-admin-api-stg1.sushivesla.net/api/${Versions.v1}`
//     : `https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru/api/${Versions.v1}`

// export const authUrl =
//   process.env.REACT_APP_ENV === "dev"
//     ? "https://ftl-tech-admin-api-stg1.sushivesla.net/not-secure/api/v1/auth"
//     : "https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru/not-secure/api/v1/auth"

export const baseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.DEV_URL
      ? process.env.DEV_URL
      : `https://consumers.tech-admin.fdelivery.first.ftl-dev.ru/api/${Versions.v1}`
    : process.env.PROD_URL
    ? process.env.PROD_URL
    : `https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru/api/${Versions.v1}`

export const authUrl =
  process.env.REACT_APP_ENV === "dev"
    ? process.env.AUTH_DEV_URL
      ? process.env.AUTH_DEV_URL
      : "https://consumers.tech-admin.fdelivery.first.ftl-dev.ru/not-secure/api/v1/auth"
    : process.env.AUTH_PROD_URL
    ? process.env.AUTH_PROD_URL
    : "https://consumers.tech-admin.fdelivery.first.foodtech-lab.ru/not-secure/api/v1/auth"

export const repository = createRepositoryInstance({
  baseUrl,
  tokenRefresh: () => AuthAPI.refresh(),
})
repository.defaults.timeout =
  process.env.DEFAULT_CONNECTION_TIMEOUT !== undefined
    ? Number(process.env.DEFAULT_CONNECTION_TIMEOUT)
    : 30000

export const AuthAPI = new AuthClient(
  repository,
  {
    signIn: `${authUrl}/login/email`,
    signOut: `${authUrl}/logout`,
    refresh: `${authUrl}/refresh`,
  },
  {
    loginPropName: "email",
  }
)

export const UtilsAPI = createUtilsRepository(repository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Country

export const CountryAPI = createCountryRepository(repository, UtilsAPI)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})

//ExternalServices

const normalizeExternalServicePayload = (
  payload: ExternalService
): ExternalService => ({
  activeStatus: payload.activeStatus,
  baseApiUrl: payload.baseApiUrl,
  microserviceInteractionSettings: payload.microserviceInteractionSettings,
  name: payload.name,
  type: payload.type,
})

export const ExternalServicesAPI = createEntityRepository<
  ExternalServiceRequest,
  ExternalService<"GET">,
  ExternalService,
  ExternalService<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeExternalServicePayload,
  basePath: "external-services",
  utils: UtilsAPI,
})

//ExternalServicesProperties
const normalizeExternalServicePropertyPayload = (
  payload: ExternalServiceProperty
): ExternalServiceProperty => ({
  activeStatus: payload.activeStatus,
  isRequired: payload.isRequired,
  localized: payload.localized,
  localizedHelper: payload.localizedHelper,
  name: payload.name,
  type: payload.type,
})

export const ExternalServicePropertiesAPI = Object.freeze({
  getList: (
    externalServiceId: string,
    params: ExternalServicePropertyRequest
  ): Promise<AxiosResponse<ListResponse<ExternalServiceProperty<"GET">>>> => {
    return UtilsAPI.getWithParams(
      `external-services/${externalServiceId}/properties`,
      params
    )
  },
  getAll: (
    externalServiceId: string
  ): Promise<AxiosResponse<ListResponse<ExternalServiceProperty<"GET">>>> => {
    return repository.get(
      `external-services/${externalServiceId}/properties/all`
    )
  },

  create: (
    externalServiceId: string,
    payload: ExternalServiceProperty
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceProperty<"GET">>>> => {
    return repository.post(
      `external-services/${externalServiceId}/properties`,
      normalizeExternalServicePropertyPayload(payload)
    )
  },
  edit: (
    externalServiceId: string,
    payload: ExternalServiceProperty<"PATCH">
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceProperty<"GET">>>> => {
    return repository.patch(
      `external-services/${externalServiceId}/properties/${payload.id}`,
      normalizeExternalServicePropertyPayload(payload)
    )
  },
  getById: (
    externalServiceId: string,
    propertyId?: string
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceProperty<"GET">>>> => {
    return repository.get(
      `external-services/${externalServiceId}/properties/${propertyId}`
    )
  },

  switchStatus: (
    id: string,
    propertyId: string
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(
      `external-services/${id}/properties/${propertyId}/active-status`
    )
  },
})

//ExternalServiceAccounts

const normalizeExternalServiceAccountPayload = (
  payload: ExternalServiceAccount
): ExternalServiceAccount => ({
  activeStatus: payload.activeStatus,
  brandId: payload.brandId,
  isDefault: payload.isDefault,
  name: payload.name,
  organizationId: payload.organizationId,
  properties: payload.properties,
  type: payload.type,
})

export const ExternalServiceAccountsAPI = Object.freeze({
  getList: (
    externalServiceId: string,
    params: ExternalServiceAccountRequest
  ): Promise<AxiosResponse<ListResponse<ExternalServiceAccount<"GET">>>> => {
    return UtilsAPI.getWithParams(
      `external-services/${externalServiceId}/accounts`,
      params
    )
  },

  create: (
    externalServiceId: string,
    payload: ExternalServiceAccount
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceAccount<"GET">>>> => {
    return repository.post(
      `external-services/${externalServiceId}/accounts`,
      normalizeExternalServiceAccountPayload(payload)
    )
  },
  edit: (
    externalServiceId: string,
    payload: ExternalServiceAccount<"PATCH">
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceAccount<"GET">>>> => {
    return repository.patch(
      `external-services/${externalServiceId}/accounts/${payload.id}`,
      normalizeExternalServiceAccountPayload(payload)
    )
  },
  getById: (
    externalServiceId: string,
    propertyId?: string
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceAccount<"GET">>>> => {
    return repository.get(
      `external-services/${externalServiceId}/accounts/${propertyId}`
    )
  },
  switchStatus: (
    externalServiceId: string,
    propertyId: string
  ): Promise<AxiosResponse<BasicResponse<ExternalServiceAccount<"GET">>>> => {
    return repository.post(
      `external-services/${externalServiceId}/accounts/${propertyId}/active-status`
    )
  },
})

//Source

export const SourcesAPI = Object.freeze({
  getAll: (): Promise<AxiosResponse<ListResponse<string[]>>> => {
    return repository.get(`sources/all`)
  },
})

//SMS

export const SmsAPI = Object.freeze({
  ...createEntityRepository<SMSRequest, SMS<"GET">, SMS, SMS<"PATCH">>({
    repositoryInstance: repository,
    basePath: "sms",
    utils: UtilsAPI,
  }),

  deleteById: (
    id: string
  ): Promise<AxiosResponse<BasicResponse<SMS<"GET">>>> => {
    return repository.post(`sms/${id}/delete`)
  },
  deleteByPhone: (
    phoneNumber: string
  ): Promise<AxiosResponse<BasicResponse<SMS<"GET">>>> => {
    return repository.post(`sms/delete-by-phone`, { phone: phoneNumber })
  },
  getAccessCode: (): Promise<AxiosResponse> => {
    return repository.get(`sms-settings/debug-mode/access-code`)
  },
  getWithoutCodes: (): Promise<AxiosResponse> => {
    return repository.get(`sms-settings/debug-mode/phones`)
  },
  putWithoutCodes: (request: string[]): Promise<AxiosResponse> => {
    return repository.put(`sms-settings/debug-mode/phones`, request)
  },
})

//Order sync service accounts

export const OrderSyncServiceAccountAPI = Object.freeze({
  getAll: (): Promise<AxiosResponse<ListResponse<OrderSyncServiceAccount>>> => {
    return repository.get(`order-sync-service-accounts/all`)
  },
})

//Users

const normalizeUserPayload = (
  payload: User | User<"PATCH">,
  type: "post" | "patch" = "post"
): Omit<User | User<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    accountStatus: payload.accountStatus,
    countryId: payload.countryId,
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    secondName: payload.secondName,
    phoneNumber: payload.phoneNumber,
    profileImageId: payload.profileImageId,
    roleIds: payload.roleIds,
  }
  if (payload.password && type === "post")
    normalized.password = payload.password

  return normalized
}

export const UsersAPI = Object.freeze({
  ...createEntityRepository<UserRequest, User<"GET">, User, User<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeUserPayload,
    basePath: "users",
    utils: UtilsAPI,
  }),
  deleteAvatar: (id: string) => {
    return repository.delete(`users/${id}/profile-image`)
  },
  changePassword: (
    id: string,
    newPassword: string
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`users/${id}/password`, { newPassword })
  },
})

//Roles

export const RoleAPI = createEntityRepository<RoleRequest, Role>({
  repositoryInstance: repository,
  basePath: "roles",
  utils: UtilsAPI,
})

//Organization

const normalizeOrganizationPayload = (payload: Organization): Organization => ({
  activeStatus: payload.activeStatus,
  name: payload.name,
})

export const OrganizationAPI = createEntityRepository<
  OrganizationRequest,
  Organization<"GET">,
  Organization,
  Organization<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeOrganizationPayload,
  basePath: "organizations",
  utils: UtilsAPI,
})

//Brands

const normalizeBrandPayload = (payload: Brand): Brand => ({
  name: payload.name,
  activeStatus: payload.activeStatus,
  logoId: payload.logoId,
  organizationId: payload.organizationId,
})

export const BrandAPI = createEntityRepository<
  BrandRequest,
  Brand<"GET">,
  Brand,
  Brand<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeBrandPayload,
  basePath: "brands",
  utils: UtilsAPI,
})
